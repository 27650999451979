<template>
  <div class="BlackListShopMember" @click="num=0">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods/goodsList' }">商品列表</el-breadcrumb-item>
      <el-breadcrumb-item>商品记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="chain_good_id" label="商品ID"> </el-table-column>
          <el-table-column prop="good_code" label="商品条码"></el-table-column>
          <el-table-column prop="good_name" label="商品名称"> </el-table-column>
          <el-table-column prop="good_price" label="商品价格"> </el-table-column>
          <el-table-column prop="good_quantity" label="商品数量"> </el-table-column>
          <el-table-column prop="good_discount_price" label="约定折扣价"> </el-table-column>
          <el-table-column prop="settle_price" label="结算金额"> </el-table-column>
          <el-table-column prop="platform_price" label="平台服务费"> </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
    </div>
    <div class="add-box" v-if="addShow" @click.stop="addShow = false"></div>
  </div>
</template>

<script>
import {VgoodRecord_api} from "@/api/order.js"
import local from "@/utils/local.js"

export default {
  data() {
    return {
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData: [],
      num: 0,
      active: 1,
      addShow: false,
      isShow: false,
      is_supermarket:'',
    }
  },
  methods: {
    //重置
    emptySearch() {
      this.memberForm = {}
      this.currentPage=1
      this.getBlackListShopMemberList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getBlackListShopMemberList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getBlackListShopMemberList()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getBlackListShopMemberList()
    },
    //获取列表
   async goodRecord(){
      let orderNnm=local.get("orderNum1")
      let res=await VgoodRecord_api({
        shop_id:orderNnm.shop_id,
        start_date:orderNnm.start_date,
        end_date:orderNnm.end_date
      })
      console.log('res'.res)
      this.tableData=res.data.data
    }
  },
  created() {
    this.goodRecord()
    this.is_supermarket=local.get("is_supermarket")
  }
}
</script>

<style lang="less" scoped>
.BlackListShopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  z-index: 0;
}
.add-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.add-Member {
  width: 600px;
  height: 580px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  box-sizing: border-box;
}
.close {
  cursor: pointer;
}
.add {
  border-bottom: #cd0550 3px solid;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #cd0550;
}
.demo-ruleForm {
  margin-top: 40px;
}
</style>